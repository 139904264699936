<script setup>
import { onMounted, onUnmounted, computed } from "vue";
import { useStore } from "vuex";

import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";

const store = useStore();

const blocks = computed({
  get() {
    return store.state.newBlock;
  },
  set(value) {
    store.commit("SET_NEW_BLOCKS", value);
  },
});

let intervalId;
onMounted(() => {
  store.dispatch("fetchNewBlock");
  intervalId = setInterval(() => {
    store.dispatch("fetchNewBlock");
  }, 10000);
});
onUnmounted(() => clearInterval(intervalId));
</script>

<template>
  <v-app>
    <Header :blocks="blocks" />
    <v-main app class="wrap pp">
      <v-alert style="font-size: 1.1rem; font-weight: 300;" color="#fafafa" class="mb-3 border" :text="$t('info')" variant="outlined" density="compact"></v-alert>
      <router-view />
    </v-main>
    <Footer />
  </v-app>
</template>

<style>
:root {
  --bg: #292929;
  --success: #e78400;
  --font-color: #e0e0e0;
  --border-color: rgba(252, 255, 255, 0.1);
  --loader: rgba(255, 255, 255, 0.1);
  --loader_bg: rgba(255, 255, 255, 0.5);
  --color: rgba(255, 255, 255, 0.8);
  --footer-color: rgba(255, 255, 255, 0.8);
  --footer-border: rgba(255, 255, 255, 0.1);
}

html,
body {
  font-family: 'Nunito', sans-serif !important;
  background-color: var(--bg) !important;
}

#app {
  font-family: "Nunito", sans-serif !important;
  background-color: var(--bg) !important;
}

.pp {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.v-application {
  font-family: "Nunito", sans-serif !important;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  font-size: 1rem !important;
  background-color: var(--bg) !important;
}

.v-main {
  font-family: "Nunito", sans-serif !important;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.wrap {
  width: 100% !important;
  max-width: 1280px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Nunito", sans-serif !important;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  font-weight: 300 !important;
}


.app-bar {
  position: relative !important;
  border-bottom: 1px solid var(--border-color) !important;
  border-left: 1px solid var(--border-color) !important;
  border-right: 1px solid var(--border-color) !important;
  -webkit-border-bottom-right-radius: 4px !important;
  -webkit-border-bottom-left-radius: 4px !important;
  -moz-border-radius-bottomright: 4px !important;
  -moz-border-radius-bottomleft: 4px !important;
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
  background-color: var(--bg) !important;
}

a {
  color: #e78400;
}

.app-footer {
  position: relative !important;
}

.coin img {
  position: absolute;
  top: 50%;
  left: 1%;
  transform: translate(1%, -50%);
}

.v-row--dense {
  margin: -7px !important;
}

.v-row--dense>.v-col,
.v-row--dense>[class*="v-col-"] {
  padding: 7px !important;
}

.name {
  font-size: 1.3rem;
  margin-left: 36px;
}

.text-muted {
  font-size: 92%;
  color: rgb(255, 255, 255, 0.6);
  margin-left: 2px !important;
}

.sub {
  font-size: 90%;
}

.v-list-item-title {
  font-size: 0.9rem !important;
}

.v-list-item {
  min-height: 45px !important;
}

.v-list-item--density-default.v-list-item--one-line {
  min-height: 45px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

ul li {
  padding: 1px 2px 1px 2px;
  margin-top: 4px;
  margin-bottom: 4px;
}

ul a {
  text-decoration: none;
  font-size: 0.9rem;
  color: rgb(255, 255, 255, 0.6);
}

ul a:link {
  color: rgb(255, 255, 255, 0.6);
}

ul a:visited {
  color: rgb(255, 255, 255, 0.6);
}

ul a:hover {
  color: rgb(255, 255, 255, 0.9);
}

ul a:active {
  color: rgb(255, 255, 255, 0.9);
}

.vue-skeletor {
  position: relative;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.14);
}

.vue-skeletor:not(.vue-skeletor--shimmerless):after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.3),
      rgba(37, 22, 22, 0));
  animation: shimmer 1.5s infinite;
  content: "";
}

.vue-skeletor--rect,
.vue-skeletor--circle {
  display: block;
}

.vue-skeletor--circle {
  border-radius: 50%;
}

.vue-skeletor--pill,
.vue-skeletor--text {
  border-radius: 9999px;
}

.vue-skeletor--text {
  line-height: 1;
  display: inline-block;
  width: 100%;
  height: inherit;
  vertical-align: middle;
  top: -1px;
  border-radius: 4px;
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.card {
  background-color: rgba(252, 255, 255, 0) !important;
  padding: 0 !important;
}

.v-field--variant-solo {
  font-family: "Nunito", sans-serif !important;
  background: rgba(64, 64, 64, 0) !important;
  box-shadow: none !important;
  border: 1px solid var(--border-color) !important;
}

.v-field--variant-solo:hover,
.v-field--variant-solo:focus,
.v-field--variant-solo:active {
  background: rgba(64, 64, 64, 0.5) !important;
}

.v-card--variant-outlined {
  border: 1px solid var(--border-color) !important;
}

.dashed {
  border-style: dashed !important;
  border-color: var(--loader_bg) !important;
  opacity: 0.2 !important;
}

.v-card-title {
  font-family: "Nunito", sans-serif !important;
  font-size: 1rem !important;
  font-weight: 300 !important;
  padding: 6px 10px !important;
}

.card-subtitle {
  font-weight: 300 !important;
  font-size: 0.9rem !important;
  opacity: 0.9 !important;
  margin-left: 10px;
  margin-top: 5px;
}

.card-text {
  font-size: 1rem !important;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.v-card-title-one {
  font-family: "Nunito", sans-serif !important;
  font-size: 1rem !important;
  font-weight: 300 !important;
  padding: 6px 10px !important;
}

.card-text-one {
  font-size: 1rem !important;
  padding: 10px !important;
}

.v-table {
  font-size: 0.9rem !important;
  background: var(--bg) !important;
  white-space: nowrap !important;
}

.v-table a {
  color: rgba(255, 255, 255, 0.8) !important;
}

.v-table__wrapper>table>tbody>tr>th,
.v-table__wrapper>table>thead>tr>th,
.v-table__wrapper>table>tfoot>tr>th {
  font-family: "Nunito", sans-serif !important;
  color: rgba(255, 255, 255, 0.85) !important;
  background: rgba(64, 64, 64, 0.3) !important;
}

.text-monospace {
  font-family: "Roboto Mono", monospace;
}

.v-code {
  font-family: "Roboto Mono", monospace;
  font-size: 0.9rem !important;
}

.table-border th+th {
  border-left: 1px solid var(--border-color);
}

.table-border td+td {
  border-left: 1px solid var(--border-color);
}

.table-border {
  border: 1px solid var(--border-color) !important;
}

.params_account {
  color: rgba(255, 255, 255, 0.8) !important;
  font-size: 15px !important;
  letter-spacing: 0.3px !important;
}

.progres {
  position: absolute !important;
  top: 94% !important;
}

.v-tab {
  font-size: 83% !important;
  font-weight: 400 !important;
  letter-spacing: 0.128px !important;
}

.v-btn.new {
  font-size: 83% !important;
  font-weight: 400 !important;
  letter-spacing: 0.128px !important;
}

.v-btn.none-transform {
  text-transform: none !important;
}

.v-tab--selected {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.v-badge__badge {
  border-radius: 4px !important;
  color: rgba(0, 0, 0, 0.7) !important;
}

.v-banner-text {
  margin-top: 8px !important;
}

.v-tooltip .v-overlay__content {
  background-color: rgb(20, 20, 20) !important;
  font-family: "Nunito", sans-serif !important;
  color: rgba(255, 255, 255, 0.85) !important;
  font-weight: 400 !important;
}

.v-toolbar-title__placeholder {
  overflow: visible !important;
  text-overflow: none !important;
}
#chart .v-expansion-panel-text__wrapper {
  padding: 0px !important;
}

#chart .v-expansion-panel--active > .v-expansion-panel-title:not(.v-expansion-panel-title--static) {
  min-height: 32px !important;
}

</style>
