<script setup>
import { onMounted, onUnmounted, computed, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { enGB, ru } from "date-fns/locale";

const store = useStore();
const { locale } = useI18n();
const languages = ref({
    eu: enGB,
    ru: ru,
});

const miners = computed({
    get() {
        return store.state.miners || [];
    },
    set(value) {
        store.commit("SET_MINERS", value);
    },
});

let page = ref(1);
let itemsPerPage = ref(15);

function next() {
    let params = {
        page: page.value,
        size: itemsPerPage.value,
    };
    store.dispatch("fetchMiners", params);
}

const totalRecords = computed({
    get() {
        return store.state.miners.totalCount;
    },
    set(value) {
        store.commit("SET_MINERS", value);
    },
});

const pageCount = computed(() => {
    return Math.ceil(totalRecords.value / itemsPerPage.value);
});

let intervalId;
onMounted(() => {
    let params = {
        page: page.value,
        size: itemsPerPage.value,
    };
    store.dispatch("fetchMiners", params);
    intervalId = setInterval(() => {
        let params = {
            page: page.value,
            size: itemsPerPage.value,
        };
        store.dispatch("fetchMiners", params);
    }, 10000);
});
onUnmounted(() => clearInterval(intervalId));
</script>
<template>
    <div class="miners">
        <h3 class="mb-2">{{ $t("miners_online") }}</h3>
        <v-table density="comfortable" class="border rounded">
            <thead>
                <tr>
                    <th>{{ $t("miner") }}</th>
                    <th class="hidden-sm-and-down">{{ $t("hashrate") }}</th>
                    <th class="hidden-sm-and-down">{{ $t("last_shares") }}</th>
                </tr>
            </thead>
            <tbody v-if="miners.totalCount > 0">
                <tr v-for="(item, index) in miners.miners" :key="index">
                    <td>
                        <v-badge dot inline left :color="item.offline ? 'red' : '#17a115'" class="mr-1"></v-badge>
                        <router-link class="text-monospace" :to="'/account/' + item.miner">
                            {{ $filters.tx(item.miner, 16, item.miner.length - 16) }}
                        </router-link>
                    </td>
                    <td class="hidden-sm-and-down"><span v-html="$filters.hashrate(item.hashrate, 2)"></span></td>
                    <td class="hidden-sm-and-down">
                        <span v-if="item.lastShare != 0">
                            <timeago :key="locale" :locale="languages[locale]" :datetime="item.lastShare * 1000" />
                        </span>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr>
                    <td colspan="3" class="text-center">
                        {{ $t("no_data") }}
                    </td>
                </tr>
            </tbody>
        </v-table>
        <v-pagination v-if="totalRecords > itemsPerPage" class="mt-2" density="comfortable" v-model="page"
            :length="pageCount" :total-visible="7" @click="next()"></v-pagination>
    </div>
</template>
