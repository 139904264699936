export default {
    name: "Kaspa",
    coin: "KAS",
    scheme: "SOLO",
    account: 'qzqa4wla84v0ae3z3j88at3v5unpwxw2fcxh00td453rm374hukw6e4saes50',
    explorer_url: 'https://explorer.kaspa.org/',
    coingecko: 'https://www.coingecko.com/en/coins/kaspa',
    explorer: {
        address: "https://explorer.kaspa.org/addresses/kaspa:",
        height: "https://explorer.kaspa.org/blocks/",
        hash: "https://explorer.kaspa.org/blocks/",
        tx: "https://explorer.kaspa.org/txs/",
    },
    ports: [
        {
            info: "GPU",
            host: "molepool.com",
            port: 4201,
            diff: 32,
        },
        {
            info: "KS0",
            host: "molepool.com",
            port: 4202,
            diff: 512,
        },
        {
            info: "KS1/KS2",
            host: "molepool.com",
            port: 4203,
            diff: 4096,
        },
        {
            info: "KS3/KS3L",
            host: "molepool.com",
            port: 4204,
            diff: 16384,
        }
    ]
};